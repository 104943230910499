import React, { Suspense } from "react";
import {lazy }from "react";
import { Route, Routes } from "react-router-dom";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import Loader from "../components/Constants/Loader.js";

// import { Error404 } from "../Pages/Error/Error404";
// import Login from "../Pages/Login/Login";
// import HomePageBody from "../Layouts/Home/HomePageBody";
// import RegistrationForm from "../Pages/Registration/RegistrationForm";
// import ForgotPassword from "../Pages/Login/ForgetPassword";
// import VerifyEmail from "../Pages/Registration/VerifyEmail";
// import JobSeekerRoutes from "./JobSeekerRoutes.js";
// import RecruiterRoutes from "./RecruiterRoutes.js"
// import EmployerRoutes from "./EmployerRoutes.js";
// import Footer from "../components/Footer/Footer.js";
// import StaticRoutes from "./StaticRoutes.js";
// import ResetPassword from "../Pages/Login/ResetPassword";
// import Header from "../components/Header/Header.js";

const Error404 = lazy(() => import("../Pages/Error/Error404"));
const Login = lazy(() => import("../Pages/Login/Login"));
const HomePageBody = lazy(() => import("../Layouts/Home/HomePageBody"));
const RegistrationForm = lazy(() => import("../Pages/Registration/RegistrationForm"));
const ForgotPassword = lazy(() => import("../Pages/Login/ForgetPassword"));
const VerifyEmail = lazy(() => import("../Pages/Registration/VerifyEmail"));
const JobSeekerRoutes = lazy(() => import("./JobSeekerRoutes.js"));
const RecruiterRoutes = lazy(() => import("./RecruiterRoutes.js"));
const EmployerRoutes = lazy(() => import("./EmployerRoutes.js"));
const Footer = lazy(() => import("../components/Footer/Footer.js"));
const StaticRoutes = lazy(() => import("./StaticRoutes.js"));
const ResetPassword = lazy(() => import("../Pages/Login/ResetPassword"));
const Header = lazy(() => import("../components/Header/Header.js"));

const AppRoutes = () => {
  return (
    <>
    <Suspense fallback={<Loader loading={true}/>}>
      <Routes>
        <Route path="/" element={<HomePageBody />} />
        <Route path="/home" element={<HomePageBody />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<RegistrationForm />} />
        <Route exact path="/forgetpassword" element={<ForgotPassword />} />        
        <Route exact path="/forget-password" element={<ResetPassword />} />
        <Route exact path="/ConfirmEmail" element={<VerifyEmail />} />
        {/* <Route exact path="/preview-Job-Details/:id" element={<PreviewJobinFullScreen />}/> */}


        {/* Static Pages Routes */}
        <Route path="/*" element={<StaticRoutes />} />
        {/* <Route path="/user/*" element={<UserRoutes />} /> */}
        
        {/* JobSekeer's Routes */} 
        <Route path="/user/*" element={<JobSeekerRoutes />} />

         {/* Recruter's Routes */}
        <Route path="/recruiter/*" element={<RecruiterRoutes />} />  


         {/* Employer's Routes */}        
        <Route path="/employer/*" element={<EmployerRoutes />} /> 
 

        {/* <Route exact path="/reset-password" element={<ResetPassword />} /> */}
        {/* Private Routes */}
        {/* <Route exact path="/changepassword" element={<PrivateRoute><ChangePassword /></PrivateRoute>}/> */}
            
        <Route path="/error-404" element={<><Header/><Error404 /><Footer/></>} />  
        <Route path="*" element={<><Header/><Error404 /><Footer/></>} />  

      </Routes>
    </Suspense>
    </>
  );
};

export default AppRoutes;
