import React from "react";
import "./App.css";
import "../src/Pages/Recruiter/RecruiterCSS.css";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { LoadScript } from "@react-google-maps/api";
// import Loader from "./components/Constants/Loader";
// import useAccountStore from "./store/useAccountStore";

// const apiKey = "AIzaSyAs-UassKCEhwYB2dz6VlsuOmBg4Ly5Uws";
// const libraries=["places", "drawing", "geometry"];
// const libraries = useMemo(() => ["places", "drawing", "geometry"], []);
function App() {
  // const getRefreshToken = useAccountStore((state) => state.getRefreshToken);
  // const signedInUserData = useAccountStore((state) => state.signedInUserData);

  // useEffect(() => {
  //   // Set up interval to refresh data every 14.58 minutes
  //   if (signedInUserData) {
  //     const intervalId = setInterval(() => {
  //       var refreshFormData = {
  //         accessToken: signedInUserData?.data?.accessToken,
  //         refreshToken: signedInUserData?.data?.refreshToken,
  //       };
  //       getRefreshToken(refreshFormData);
  //     }, 874600);
  //     // Clean up the interval on component unmount
  //     return () => clearInterval(intervalId);
  //   }
  // }, []);

  // const [mapLoader, setMapLoader] = useState(true);

  // const handleLoad = () => {
  //   // console.log("Google Maps loaded successfully.");
  //   setTimeout(() => {
  //     setMapLoader(false);
  //   }, 1000);
  // setMapLoader(false);
  // };

  // const handleError = () => {
  //   console.error("Error loading Google Maps API.");
  // };

  return (
    <div className="App">
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;


// <LoadScript
    //   googleMapsApiKey={apiKey}
    //   // libraries={["places", "drawing", "geometry"]}
    //   libraries={libraries}
    //   // onLoad={handleLoad}
    //   onError={handleError}
    // >  
{/* {mapLoader ? (
        <Loader loading={mapLoader} />
      ) : (
        <div className="App">
          <AppRoutes />
          <ToastContainer />
        </div>
      )} */}