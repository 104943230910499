import { toast } from 'react-toastify';

export const showToast = (message="Coming soon!", type = "default") => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "error":
      toast.error(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "warn":
      toast.warn(message);
      break;
    default:
      toast(message);
  }
};




export function formatDate(dateString) {
  if (!dateString) {
    return '';
  }
  let date = new Date(dateString);
  if (isNaN(date)) {
    return '';
}
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based in JavaScript
  let day = date.getDate();

  // Pad the month and day with leading zeros, if necessary
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;

  return `${year}-${month}-${day}`;
}



export function formatLocation(data) {
  return [data?.city, data?.state, data?.country].filter(Boolean).join(', ');
}


export const formatNumber = (amount) => {
  // Check if amount is defined and not null
  if (amount !== undefined && amount !== null) {
    // Convert amount to number and format with commas
    return Number(amount).toLocaleString();
  }  
  return '';
};

export function mapAndJoinWithComma(array) {
  // Check if array is undefined
  if (!array) {
    return '';
  }

  // Filter out undefined values
  const filteredArray = array?.filter(item => item !== undefined);

  // Join the remaining elements with a comma
  const result = filteredArray.join(', ');

  return result;
}

export const calculateCurrentDate=()=>{ const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const year = today.getFullYear();
  const currentDate = `${year}-${month}-${day}`;
  return currentDate;
};


export function formatCount(count, separator, singular, plural) {
  // Determine the correct word based on count
  const word = count <=1 ? singular : plural;
  
  // Construct the formatted string
  if (count === undefined || count === null) {
    return '';  // blank
  }  
    return `${count}${separator}${word}`;
}

export function convertToNumber(value) {
  if (value === '' || value === undefined || value === null || isNaN(value)) {
      return 0;
  }
  return Number(value);
}
