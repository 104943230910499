import React, { createContext, useRef, useState } from 'react';
import useJobFiltersStore from "../../../../src/store/JobFilterStore";

// Create a context
export const JobSearchMapContext = createContext();

export const JobSearchMapProvider = ({ children }) => {
  const [markers, setMarkers] = useState(useJobFiltersStore.getState().jobsByCoordinates || []);
  const [map, setMap] = useState(null);
  // const markerClustererRef = useRef(null);
  // const [markerInstances, setMarkerInstances] = useState([]);
  const mapRef = useRef(null);
  const searchPlacesref = useRef(null);

  return (
    <JobSearchMapContext.Provider value={
      { map, setMap, markers, setMarkers, mapRef, searchPlacesref }}>
      {children}
    </JobSearchMapContext.Provider>
  );
};
