import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_APP_API_BASE_URL;

const responseBody = (response) => response.data;

//request interceptors
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

//Response interceptors
axios.interceptors.response.use(
  async (response) => {
    return response;
  },

  (error) => {
    if (!error.response) {
      toast.error(error.data.title || error.title, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Promise.reject(error);
    }

    const { data, status } = error.response;

    switch (status) {
      case 400:
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      case 401:
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.clear();
        setTimeout(() => {
          window.location = "/login";
        }, 1000);

        break;
      case 404:
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      case /^5\d{2}$/.test(status):
        toast.error(data.title, {
          position: toast.POSITION.TOP_RIGHT,
        });
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
);






const requests = {
  get: (url, params) => axios.get(url, { params }).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  delete: (url) => axios.put(url).then(responseBody),
};

const Account = {
  userlogin: (values) => requests.post("api/Account/Login", values),
  refreshToken: (values) => requests.post("api/Account/refreshtoken", values),
  register: (values) => requests.post("api/Account/JobSeekerRegister", values),
  forgetPassword: (values) =>
    requests.post(
      `api/Account/ForgotPassword?Email=${values.email}&Domain=${values.domain}`
    ),
  generateOTP: (values) =>
    requests.post(`api/Account/GenerateOTP?EmailOrPhoneNo=${values}`, values),
  resetPassword: (values) => requests.post(`api/Account/ResetPassword`, values),
  changePassword: (values) =>
    requests.post(`api/Account/ChangePassword`, values),
  getJobSeeker: () => requests.get(`api/Account/GetUser`),
  getRole: (value) => requests.get("api/Account/GetRoles", value),
  confirmEmail: (values) =>
    requests.post(
      `api/Account/ConfirmEmail?token=${values.token}&email=${values.Email}`,
      values
    ),
};

const Profile = {
  getProfilePercentage: () =>
    requests.get("api/JobSeeker/ProfileCompletePercentage"),

  postJobSeekerEducationDetails: (values) =>
    requests.post("api/JobSeeker/PostJobSeekerEducationDetails", values),
  setJobSeekerSkills: (values) =>
    requests.post("api/JobSeeker/PostJobSeekerSkill", values),
  getJobSeekerEducationDetails: () =>
    requests.get("api/JobSeeker/GetJobSeekerEducationDetails"),
  getJobSeekerSkills: () => requests.get("api/JobSeeker/GetJobSeekerSkill"),
  updateJobSeeker: (values) => requests.post("api/Account/UpdateUser", values),
  getUpdatedUser: () => requests.get("api/Account/GetUser"),
  postJobSeekerExperience: (values, params) =>
    requests.post(
      `api/JobSeeker/PostJobSeekerExperience?IsFresher=${params}`,
      values
    ),
  getJobSeekerExperience: () =>
    requests.get("api/JobSeeker/GetJobSeekerExperience"),
  setJobSeekerPreference: (values) =>
    requests.post("api/JobSeeker/PostJobSeekerPreference", values),
  getJobSeekerPreference: () =>
    requests.get("api/JobSeeker/GetJobSeekerPreference"),
  getSearchJobSKill: () => requests.get("api/JobSeeker/SearchJob"),
  getResumeTracking: () => requests.get("api/JobSeeker/ResumeTracking"),
  postReportJob: (id) => requests.post(`api/JobSeeker/ReportJob?JobId=${id}`),
  getResumes: () => requests.get("api/JobSeeker/GetResumes"),

  uploadResume: (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return requests.post("api/JobSeeker/UploadResume", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteFile: (value) =>
    requests.post(`api/JobSeeker/DeleteFile?blobFilename=${value}`),
};

const Job = {
  getAllJobPostList: () => requests.get("api/Job/GetAllJobPostList"),
  getJobCount: () => requests.get("api/JobSeeker/CountAppliedJob"),
  getApplyJobList: () => requests.get("api/JobSeeker/ApplyJobList"),
  getSavedJobList: () => requests.get("api/JobSeeker/SavedJobList"),
  postSavedJob: (values) => requests.post("api/JobSeeker/SavedJob", values),
  postUnsavedJob: (values) => requests.post("api/JobSeeker/UnSavedJob", values),
  searchJob: (values) => requests.post("api/Job/FindJobs", values),  
  JobsByCoordinates: (values) => requests.post("api/Job/JobsByCoordinates", values),  
  applyJob: (values) => requests.post("api/JobSeeker/ApplyJob", values),
  getJobPostPreview: (id) => requests.get(`api/Job/GetJobPost?JobId=${id}`),
  postSubscribeNewsletter: (values) =>
    requests.post("api/Job/Subscribe", values),
  postConnectWithUs: (values) => requests.post("api/Job/ConnectWithUs", values),
};

const Recruiter = {
  getDashboardCounts: () => requests.get("/api/Recruiter/DashboardCount"),
  getRecentApplicants: () => requests.get("/api/Recruiter/RecentApplicants"),
  getApplicantsByJobTitle: (params)=> requests.get("/api/Recruiter/ApplicantsByJobTitle",params),

  getAllJobs:()=>requests.post('/api/Recruiter/GetAllJobs'),
  // getAllJobs:()=>requests.post('/api/Recruiter/GetListAllJobs'),
  
  getActiveJobs:()=>requests.post("/api/Recruiter/GetJobsByStatus?Status=true"),
  getClosedJobs:()=>requests.post("/api/Recruiter/GetJobsByStatus?Status=false"),
  // getActiveJobs:()=>requests.post("/api/Recruiter/GetListActiveJobs?Joblist=true"),
  // getClosedJobs:()=>requests.post("/api/Recruiter/GetListActiveJobs?Joblist=false"),

  getTaskId: () => requests.get("/api/Recruiter/AssignTaskCount"),
  getRecruiter: () => requests.get("/api/Recruiter/GetRecruiter"),
  updateRecruiter: (values) =>
    requests.post("/api/Recruiter/UpdateRecruiter", values),

  downloadResume: (params) =>
    requests.get("api/Recruiter/DownloadResume", params), //Id=2

  postJob: (values) => requests.post("api/Recruiter/JobPost", values),
  closeJob: (values) => requests.post("api/Recruiter/JobClosed", values),
  reopenJob: (id) => requests.post(`api/Recruiter/JobReopen?JobId=${id}`),
  inviteJobSeeker: (values) =>
    requests.post("/api/Recruiter/InviteJobSeeker", values),
  getSuggestedJobSeekers: () =>
    requests.get("/api/Recruiter/SuggestedJobSeeker"),

  getApplicantList: () => requests.get("/api/Recruiter/ApplicantsList"),
  getApplicantThroughJobId: (params) =>
    requests.get("/api/Recruiter/GetApplicantThroughJobId", params), //JobId=3

  getAllDetailsOfApplicants: (params) =>
    requests.get("/api/Recruiter/AllDetailsOfApplicants", params), //Id=1
  getFindJobSeekerThroughAppliedID: (params) =>
    requests.get("/api/Recruiter/FindJobSeekerThroughAppliedID", params), //id=1

  postSeenApplicantsInJobs: (params) =>
    requests.post("/api/Recruiter/SeenApplicantsInJobs", params),
  getSuggestedJobSeekerByJob: (id) =>
    requests.get(`/api/Recruiter/SuggestedJobSeekerByJob?JobId=${id}`),
  postInviteJobSeekersByJobId: (values) =>
    requests.post("/api/Recruiter/InviteJobSeekersByJobId", values),
};

const Employer = {
  employerRegister: (values) =>
    requests.post("api/Employer/EmployerRegister", values),
  getRecruiterList: (params) =>
    requests.get("/api/Employer/RecruiterList", params),
  inviteRecruiter: (values) =>
    requests.post("/api/Employer/InviteRecruiter", values),
  deleteRecruiter: (email) =>
    requests.post(`/api/Employer/DeleteRecruiter?Email=${email}`),
  editRecruiter: (values) =>
    requests.post("/api/Employer/EditRecruiter", values),
  ActiveRecruiter: (email) =>
    requests.post(`/api/Employer/ActiveRecruiter?RecruiterEmail=${email}`),
  DeactiveRecruiter: (email) =>
    requests.post(`/api/Employer/DeactiveRecruiter?RecruiterEmail=${email}`),

  getCountJobPost: () => requests.get("/api/Employer/CountJobPosts"),
  getActiveJobs: () =>
    requests.post("/api/Employer/ListActiveOrInactiveJobs?Joblist=true"),
  getClosedJobs: () =>
    requests.post("/api/Employer/ListActiveOrInactiveJobs?Joblist=false"),
  getRecentApplicants: () => requests.get("/api/Recruiter/RecentApplicants"),
  modifyEmployer: (values) =>
    requests.post("api/Employer/ModifyEmployer", values),
  uploadFeaturedImage: (file) => requests.post("api/Employer/UploadFeaturedImage", file),
  deleteFeaturedImages: (value) => requests.post("api/Employer/DeleteFeaturedImages",value),
  getEmployer: () => requests.get("/api/Employer/GetEmployer"),
};

// const Master = {
//   // Master Data JobSeeker Form API Methods
//   getDegree: () => requests.get("api/Master/GetDegreeList"),
//   getFieldOfStudyList: () => requests.get("api/Master/GetFieldOfStudyList"),
//   getUniversity: () => requests.get("api/Master/GetUniversityList"),
//   getUniversityandSchoolList: (params) =>requests.get("api/Master/GetUniversityAndSchoolList", params),
//   getComplitionYear: () => requests.get("api/Master/GetYearOfComplitionList"),
//   getSkills: () => requests.get("api/Master/GetSkillList"),
//   getJobType: () => requests.get("api/Master/GetJobTypesList"),
//   getJobSchedule: () => requests.get("api/Master/GetJobSchedule"),
//   getPreferLocationList: (params) => requests.get("api/Master/GetPreferLocationList",params),
//   jobTypeList: () => requests.get("api/Master/GetJobTypesList"),
//   industryList: () => requests.get("api/Master/GetIndustry"),
//   educationList: () => requests.get("api/Master/GetDegreeList"),
//   getCountryList: () => requests.get("api/Master/GetCountryList"),
//   getAllowedCountryList: () => requests.get("api/Master/AllowedCountry"),
//   getLanguagesList: () => requests.get("api/Master/GetlanguagesList"),
//   getJobSupplimentalPay: () => requests.get("api/Master/GetJobSupplimentalPay"),
//   getJobBenifitsOffer: () => requests.get("api/Master/GetJobBenifitsOffer"),
// };

// Master Data JobSeeker Form API Methods
const Master = {
  getDegree: (params) => requests.get("api/Master/GetDegreeList", params),
  getFieldOfStudyList: (params) => requests.get("api/Master/GetFieldOfStudyList", params),
  getUniversity: (params) => requests.get("api/Master/GetUniversityList", params),
  getUniversityandSchoolList: (params) => requests.get("api/Master/GetUniversityAndSchoolList", params),
  getComplitionYear: (params) => requests.get("api/Master/GetYearOfComplitionList", params),
  getSkills: (params) => requests.get("api/Master/GetSkillList", params),
  getJobType: (params) => requests.get("api/Master/GetJobTypesList", params),
  getJobSchedule: (params) => requests.get("api/Master/GetJobSchedule", params),
  getPreferLocationList: (params) => requests.get("api/Master/GetPreferLocationList", params),
  jobTypeList: (params) => requests.get("api/Master/GetJobTypesList", params),
  industryList: (params) => requests.get("api/Master/GetIndustry", params),
  educationList: (params) => requests.get("api/Master/GetDegreeList", params),
  getCountryList: (params) => requests.get("api/Master/GetCountryList", params),
  getAllowedCountryList: (params) => requests.get("api/Master/AllowedCountry", params),
  getLanguagesList: (params) => requests.get("api/Master/GetlanguagesList", params),
  getJobSupplimentalPay: (params) => requests.get("api/Master/GetJobSupplimentalPay", params),
  getJobBenifitsOffer: (params) => requests.get("api/Master/GetJobBenifitsOffer", params),
};

const Common = {
  uploadFeaturedImages: (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`files`, file, file.name); // Using 'files' as the key for each file
    });
    return requests.post("api/Employer/UploadFeaturedImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getFeaturedImages:() => requests.get("api/Employer/GetFeaturedImages"),
  uploadProfileImage: (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return requests.post("api/Common/UploadProfileImage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getProfileImages: () => requests.get("api/Common/GetProfileImages"),
  deleteProfileImages: (value) =>
    requests.post(`api/Common/DeleteProfileImages?blobFilename=${value}`),

  DownloadResume: (params) => requests.get("api/Common/DownloadResume", params), //Id=2

  postJob: (values) => requests.post("api/Common/JobPost", values),
  closeJob: (values) => requests.post("api/Common/JobClosed", values),

  inviteJobSeeker: (values) =>
    requests.post("/api/Common/InviteJobSeeker", values),

  getApplicantList: () => requests.get("/api/Common/ApplicantsList"),
  getSuggestedJobSeekers: () => requests.get("/api/Common/SuggestedJobSeeker"),
  getApplicantThroughJobId: (params) =>
    requests.get("/api/Common/GetApplicantThroughJobId", params), //JobId=3
  getAllDetailsOfApplicants: (params) =>
    requests.get("/api/Common/AllDetailsOfApplicants", params), //Id=1
  getFindJobSeekerThroughAppliedID: (params) =>
    requests.get("/api/Common/FindJobSeekerThroughAppliedID", params), //id=1

  postSeenApplicantsInJobs: (params) =>
    requests.post("/api/Common/SeenApplicantsInJobs", params), //JobId=3
};

const Services = {
  Account,
  Profile,
  Job,
  Recruiter,
  Employer,
  Master,
  Common,
};

export default Services;

// let email = "";

// const storeData = JSON.parse(localStorage.getItem("account"));
// if (storeData) {
//   const userDataString =
//     storeData.state &&
//     storeData.state.signedInUserData &&
//     storeData.state.signedInUserData;
//   email = userDataString?.data?.users?.email;
// }
