import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import Services from "../services/Services";
import { convertToNumber } from "../Utility/utils";

const useJobFiltersStore = create(
  persist(
    devtools((set,get) => ({
      jobsByCoordinates: [],
      lat: 0,
      lng: 0,
      distance: 10,
      responseFindJobsDTO: {
        jobSkill: "",
        jobTitle: "",
        jobExperience: 0,
        salary: "",
        jobLocation: "",
        jobIndustry: "",
        jobType: "",
        jobEducation: "",
        startDate: "1987-01-01",
        endDate: new Date().toLocaleDateString("fr-CA"),
      },
      setLat: (lat) => set((state) => ({ ...state, lat })),
      setLng: (lng) => set((state) => ({ ...state, lng })),
      setDistance: (distance) => set((state) => ({ ...state, distance })),
      setJobSkill: (jobSkill) =>
        set((state) => ({
          responseFindJobsDTO: { ...state.responseFindJobsDTO, jobSkill },
        })),
      setJobTitle: (jobTitle) =>
        set((state) => ({
          responseFindJobsDTO: { ...state.responseFindJobsDTO, jobTitle },
        })),
      setJobExperience: (jobExperience) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            jobExperience,
          },
        })),
      setSalary: (salary) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            salary,
          },
        })),
      setJobLocation: (jobLocation) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            jobLocation,
          },
        })),
      setJobIndustry: (jobIndustry) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            jobIndustry,
          },
        })),
      setJobType: (jobType) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            jobType,
          },
        })),
      setJobEducation: (jobEducation) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            jobEducation,
          },
        })),
      setStartDate: (startDate) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            startDate,
          },
        })),
      setEndDate: (endDate) =>
        set((state) => ({
          responseFindJobsDTO: {
            ...state.responseFindJobsDTO,
            endDate,
          },
        })),
      showPreview: {},
      setShowPreview: (value) => set({ showPreview: value }),     
      setjobsByCoordinates:(value)=> set({jobsByCoordinates: value || []}),
      jobLoading: false,
      setJobLoading: (value)=> set({jobsByCoordinates: value}),
      clearFilters: ()=> set({responseFindJobsDTO: {
        jobSkill: "",
        jobTitle: "",
        jobExperience: 0,
        salary: "",
        jobLocation: "",
        jobIndustry: "",
        jobType: "",
        jobEducation: "",
        startDate: "1987-01-01",
        endDate: new Date().toLocaleDateString("fr-CA"),
      },}),
      getJobsByCoordinates: async () => {
        set({jobLoading: true})
        // set({ jobsByCoordinates: []});
        const { lat, lng, distance, responseFindJobsDTO } = get();
        const data = {
          lat,
          lng,
          distance,
          responseFindJobsDTO: {
            ...responseFindJobsDTO,
            salary: convertToNumber(responseFindJobsDTO.salary),
          },
        };
        try {
          const res = await Services.Job.JobsByCoordinates(data);
            set({ jobsByCoordinates: res?.data || []});
            set({jobLoading: false})
          return res?.data;
        } catch (error) {
          console.error("Error fetching jobs by coordinates:", error);
          set({jobLoading: false})
          return null; // Handle error gracefully in your application
        }
      },
    })),
    { name: "jobFilters" }
  )
);

export default useJobFiltersStore;
