import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { JobSearchMapProvider } from "./Pages/JobSeekerFlow/Contexts/JobSearchMapContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <JobSearchMapProvider>
    <App />
  </JobSearchMapProvider>
  </BrowserRouter>
);
